import React from "react";

import Typewriter from "typewriter-effect";
import { BrowserView, MobileView, isBrowser, isMobile , browserName, CustomView } from 'react-device-detect';

import nasdaq from './pics/nasdaq2.jpeg';
import nas3 from './pics/nas3.png';
import spec from './pics/spectacles.png';
import discord from './pics/disc3.png';
import discord2 from './pics/discold.png';
import wonderwalk from './pics/wonderwalk.png';
import nordstrom from './pics/nordstrom.png';
import nasa from './pics/nasa.png';
import discord3 from './pics/discord2.png';
import ogdisc from './pics/ogdisc.png';
import aws from './pics/awslogo.png';
import ibm from './pics/ibmlogo2.png';
import Pdf from './pics/Garcia_Dylan_Resume.pdf'
import Portfolio from './pics/Dylan_Garcia_Portfolio.pdf'

function Home() {
return(


<div class = "flex flex-col  min-h-screen "> {/* container */}



    <div class = "flex  pt-2 px-4 py-2 justify-between items-center"> {/* header */}
        <div class = "mr-auto">
            <span class ='grow  text-black-100 font-bold  text-xl  hover:text-transparent  hover:bg-clip-text hover:bg-gradient-to-r hover:from-blue-500 hover:to-purple-700' > <a href="/"> Dylan Garcia </a></span>
        </div>
        <div class = 'px-0 font-bold text-black-900 text-l  hover:text-transparent  hover:bg-clip-text hover:bg-gradient-to-r hover:from-blue-500 hover:to-purple-700'>
          <a href={Portfolio}> Portfolio </a>

        </div>

        <div class = 'px-2 font-bold text-black-900 text-l  hover:text-transparent  hover:bg-clip-text hover:bg-gradient-to-r hover:from-blue-500 hover:to-purple-700'>
          <a href={Pdf}> CV </a>
        </div>

        <div class = "text-black-900 font-bold text-l  hover:text-transparent  hover:bg-clip-text hover:bg-gradient-to-r hover:from-blue-500 hover:to-purple-700">
          <a href="/About"> About </a>
        </div>
      </div>
{/* body */}
{/* background was blue 400 this is the entire body pt-20 px-80 py-20 */}
<div class= "flex ">
{/*}<div class = "flex-[2_2_50%] bg-blue-400">

</div> */}



  <div class ="flex flex-col my-80 mx-auto ">
      {/*  the hello!*/}



      <div class = "py-2 flex justify-center mx-auto text-2xl font-extrabold text-transparent text-8xl bg-clip-text bg-gradient-to-r from-purple-500 to-blue-700">
        <Typewriter options={{loop:true}}

       onInit={(typewriter)=> {

       typewriter


       .typeString("Hello.")

       .pauseFor(1100)
       .deleteAll()
       .typeString("I'm Dylan.")
       .pauseFor(1500)
       .start();
       }}
       />
      </div>
            {/*  Title!*/}
    <div class = "flex justify-center  ">

    <span class = " text-lg"><p>I'm a <span class = "font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-blue-700"> Quantitative User Researcher </span></p>



   </span>

    </div>

    <div class = " text-m flex justify-center ml-64 mr-64 whtespace-normal	 ">



                </div>

  </div>

  {/*}
  <div class ="flex-[2_2_50%] bg-blue-400">
  ok
  </div>
  */}


</div>
<div class = "py-20">

</div>

  <div id="portfolio" class = "flex justify-center m-8 text-2xl font-extrabold text-transparent text-4xl bg-clip-text bg-gradient-to-r from-purple-500 to-blue-700">  Projects </div>



<div class = "flex flex-wrap ml-auto mr-auto justify-center ">

        <div class =  " w-5/12 mb-1 ml-1 mr-10 relative flex-col mt-1 bg-white-900 shadow-lg hover:shadow-gray-400 overflow-hidden rounded-2xl max-w-s  ">
          {/*<a href={Portfolio}> */}
          <div class = "max-h-fit ">
                <img class = "w-800"  src={nas3} alt="Nasdaq" />
                </div>
                <div class="flex flex-wrap justify-center px-1 pt-3 pb-3">
                  <span class="inline-block bg-purple-600 px-3 py-1 text-sm rounded-full font-semibold text-white mr-2 mb-2">UX Researcher</span>
                  <span class="inline-block bg-purple-600  px-3 py-1 text-sm rounded-full  font-semibold text-white  mr-2 mb-2">Figma</span>
                  <span class="inline-block bg-purple-600  px-3 py-1 text-sm rounded-full  font-semibold text-white mr-2 mb-2">Semi-Structured Interviews</span>
                </div>


                    <div class = " px-2 font-bold font-lg">
                      Nasdaq: UX Reseacher
                    </div>
                    <div class = "px-2">
                    Using Figma to create standardized components. Researching new potential areas of improvement.
                    </div>
                  {/*}  </a> */}
                </div>
                <div class =  "w-5/12 mb-1 ml-1 mr-10 relative flex-col mt-1 bg-white-900 shadow-lg hover:shadow-gray-400 overflow-hidden rounded-2xl max-w-s  ">
                  {/*}<a href="/Thesis"> */}
                    <div class = "max-h-fit">
                        <img src={ogdisc} alt="Nasdaq" />
                        </div>
                        <div class="flex flex-wrap justify-center mr-auto ml-auto pt-3 px-1 pb-3">

                          <span class="inline-block bg-purple-600 px-3 py-1 text-sm font-semibold text-white mr-2 mb-2 rounded-full ">Surveys</span>
                          <span class="inline-block bg-purple-600 px-3 py-1 text-sm font-semibold text-white mr-2 mb-2 rounded-full ">Focus Groups</span>
                          <span class="inline-block bg-purple-600 px-3 py-1 text-sm font-semibold text-white mr-2 mb-2 rounded-full ">Semi-Structured Interviews </span>
                        </div>


                        <div class = " px-2 font-bold font-lg">
                            <a href="/About">  </a>
                         MSc Dissertation: Community Building in Discord
                        </div>
                        <div class = "px-2">
                        Currently researching how discord building affects community building in young adults.
                        </div>
                      {/*  </a> */}
                        </div>
                        <div class =  "w-5/12 mb-1 ml-1 mr-10 relative flex-col mt-1 bg-white-900 shadow-lg hover:shadow-gray-400 overflow-hidden rounded-2xl max-w-s  ">
                            <div class = "max-h-fit">
                                <img src={wonderwalk} alt="Nasdaq" />
                                </div>
                                <div class="flex flex-wrap justify-center pt-3 px-1 pb-3">
                                  <span class="inline-block bg-purple-600 px-3 py-1 text-sm font-semibold text-white mr-2 mb-2 rounded-full">UX Research</span>
                                  <span class="inline-block bg-purple-600  px-3 py-1 text-sm font-semibold text-white  mr-2 mb-2 rounded-full">Developer</span>
                                              <span class="inline-block bg-purple-600 px-3 py-1 text-sm font-semibold text-white  mr-2 mb-2 rounded-full">Storyboarding</span>
                                  <span class="inline-block bg-purple-600  px-3 py-1 text-sm font-semibold text-white  mr-2 mb-2 rounded-full">Semi-Structure Interviews</span>
                                </div>
                                <div class = " px-2 font-bold font-lg">
                                 Wonderwalking: Using AR to bring communities together
                                </div>
                                <div class = "px-2">
Used agile work pratices to develop a prototype for an AR app.                             </div>
                                </div>

                                <div class =  "w-5/12 mb-1 ml-1 mr-10 relative flex-col mt-1 bg-white-900 shadow-lg hover:shadow-gray-400 overflow-hidden rounded-2xl max-w-s  ">
                                    <div class = "max-h-fit">
                                        <img class = "mt-1" src={nordstrom} alt="Nasdaq" />
                                        </div>
                                        <div class="flex flex-wrap justify-center pt-3 px-1 pb-3">
                                          <span class="inline-block bg-purple-600 px-3 py-1 text-sm font-semibold text-white  mr-2 mb-2 rounded-full">Front-End Developer</span>
                                          <span class="inline-block bg-purple-600  px-3 py-1 text-sm font-semibold text-white mr-2 mb-2 rounded-full">React</span>
                                          <span class="inline-block bg-purple-600  px-3 py-1 text-sm font-semibold text-white mr-2 mb-2 rounded-full">Javascript</span>
                                          <span class="inline-block bg-purple-600 px-3 py-1 text-sm font-semibold text-white mr-2 mb-2 rounded-full ">Docker</span>
                                          <span class="inline-block bg-purple-600 px-3 py-1 text-sm font-semibold text-white mr-2 mb-2 rounded-full ">Kubernetes</span>
                                          <span class="inline-block bg-purple-600 px-3 py-1 text-sm font-semibold text-white mr-2 mb-2 rounded-full ">AWS</span>
                                        </div>
                                        <div class = " px-2 font-bold font-lg">
                                          Nordstrom: UX Developer
                                        </div>
                                        <div class = "px-2">
Developed a debugging tool aimed to reduce errors in data pipeline.                                        </div>
                                    </div>

                                        <div class =  "w-5/12 mb-1 ml-1 mr-10 relative flex-col mt-1 bg-white-900 shadow-lg hover:shadow-gray-400 overflow-hidden rounded-2xl max-w-s  ">
                                            <div class = "max-h-fit">
                                                <img class = " mt-12"  src={nasa} alt="Nasdaq" />
                                                </div>
                                                <div class="flex flex-wrap justify-center px-1 pt-3 pb-3">
                                                  <span class="inline-block bg-purple-600 rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2">UX Research</span>
                                                  <span class="inline-block bg-purple-600 rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2">Figma</span>
                                                  <span class="inline-block bg-purple-600 rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2">Interviews</span>
                                                </div>
                                                    <div class = " px-2 font-bold font-lg">
                                                      NASA: UX Researcher/Developer
                                                    </div>
                                                    <div class = "px-2">
                                                    Restricted, reach out to learn more.
                                                    </div>
                                                </div>

                                                                                        <div class =  "w-5/12 mb-1 ml-1 mr-10 relative flex-col mt-1 bg-white-900 shadow-lg hover:shadow-gray-400 overflow-hidden rounded-2xl max-w-s  ">
                                                                                            <a href={Portfolio}>
                                                                                            <div class = "max-h-fit">
                                                                                                <img class = "w-800"  src={discord} alt="Nasdaq" />
                                                                                                </div>
                                                                                                <div class="px-1 flex flex-wrap justify-center pt-3 pb-3">
                                                                                                  <span class="inline-block bg-purple-600 rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2">UX Research</span>
                                                                                                  <span class="inline-block bg-purple-600 rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2">Figma</span>
                                                                                                  <span class="inline-block bg-purple-600 rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2">Interviews</span>
                                                                                                </div>
                                                                                                    <div class = "px-2 font-bold font-lg">
                                                                                                      Webpage for UI/UX Portfolio
                                                                                                    </div>
                                                                                                    <div class = "px-2">

                                                                                                    </div>
                                                                                                    </a>
                                                                                                </div>




  <div id="more" class = "flex justify-center m-8 text-2xl font-extrabold text-transparent text-4xl bg-clip-text bg-gradient-to-r from-purple-500 to-blue-700">  Other Dev Experiences </div>

<div class = "flex flex-wrap ml-auto mr-auto justify-center">

  <div class =  " w-5/12 mb-1 ml-1 mr-10 relative flex-col mt-1 bg-white-900 shadow-lg hover:shadow-gray-400 overflow-hidden rounded-2xl max-w-s  ">
    {/*<a href="/Nasdaq"> */}
    <div class = "max-h-fit ">
          <img class = "w-800"  src={aws} alt="AWS" />
          </div>
          <div class="flex flex-wrap justify-center px-1 pt-3 pb-3">
            <span class="inline-block bg-purple-600 px-3 py-1 text-sm rounded-full font-semibold text-white mr-2 mb-2">Python</span>
            <span class="inline-block bg-purple-600  px-3 py-1 text-sm rounded-full  font-semibold text-white  mr-2 mb-2">AWS</span>
            <span class="inline-block bg-purple-600  px-3 py-1 text-sm rounded-full  font-semibold text-white mr-2 mb-2">Alexa API</span>
          </div>
          <div class = " px-2 font-bold font-lg">
            AWS: Software Developer
          </div>

</div>

<div class =  " w-5/12 mb-1 ml-1 mr-10 relative flex-col mt-1 bg-white-900 shadow-lg hover:shadow-gray-400 overflow-hidden rounded-2xl max-w-s  ">
  {/*<a href="/Nasdaq"> */}
  <div class = "max-h-fit ">
        <img class = "w-800"  src={ibm} alt="Nasdaq" />
        </div>
        <div class="flex flex-wrap justify-center px-1 pt-3 pb-3">
          <span class="inline-block bg-purple-600 px-3 py-1 text-sm rounded-full font-semibold text-white mr-2 mb-2">Python</span>
          <span class="inline-block bg-purple-600  px-3 py-1 text-sm rounded-full  font-semibold text-white  mr-2 mb-2">Datacience</span>
          <span class="inline-block bg-purple-600  px-3 py-1 text-sm rounded-full  font-semibold text-white mr-2 mb-2">Backend</span>
        </div>
        <div class = " justify-center px-2 mt-3 font-bold font-lg">
          IBM: Software Engineer
        </div>

</div>


</div>


</div>






<div class="mt-16 flex flex-col items-center"><div class="mb-3 flex space-x-4"><a class="text-lg text-gray-500 transition hover:" target="_blank" rel="noopener noreferrer" href="mailto:contact@dylangarcia.io"><span class="sr-only">mail</span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="fill-current text-gray-700 hover:text-orange-500 dark:text-gray-200 dark:hover:text-purple-400 h-6 w-6"><path d="M2.003 5.884 10 9.882l7.997-3.998A2 2 0 0 0 16 4H4a2 2 0 0 0-1.997 1.884z"></path><path d="m18 8.118-8 4-8-4V14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8.118z"></path></svg></a>

{/*
<a class="text-sm text-gray-500 transition hover:text-gray-600" target="_blank" rel="noopener noreferrer" href="https://dgarci11github.com"><span class="sr-only">github</span><svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="fill-current text-gray-700 hover:text-purple-500 dark:text-gray-200 dark:hover:text-purple-400 h-6 w-6">
<path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"></path></svg></a>
*/}


<a class="text-sm text-gray-500 transition hover:text-gray-600" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com"><span class="sr-only">linkedin</span><svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="fill-current text-gray-700 hover:text-blue-500 dark:text-gray-200 dark:hover:text-blue-400 h-6 w-6"><path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433a2.062 2.062 0 0 1-2.063-2.065 2.064 2.064 0 1 1 2.063 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"></path></svg></a>



</div><div class="mb-2 flex space-x-2 text-sm text-gray-500 dark:text-gray-400"><div>Dylan Garcia ©2025</div></div><div class="mb-8 text-sm text-gray-500 dark:text-gray-400"><a target="_blank" rel="noopener noreferrer"> contact@dylangarcia.io</a></div></div>


{/* biggest div */}
  </div>
);
}




export default Home;
