import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";

import ucl from './pics/ucl.png';
import brown from './pics/brown2.png';
import brown2 from './pics/brown.png';
import figma from './pics/figma.png';
import docker from './pics/docker.png';
import kubernetes from './pics/kubernetes.png';
import python from './pics/python.png';
import java from './pics/java.png';
import react from './pics/react.png';
import adobe from './pics/adobe.png';
import git from './pics/git.png';
import jira from './pics/jira.png';
import miro from './pics/miro.png';
import risd from './pics/risd2.png';
import amazon from './pics/aws.png';
import jup from './pics/jup.png';
import me2 from './pics/me3.png';

import me from './pics/me.jpg';
function About() {
return (
  <div>

  <div class = "flex flex-col  min-h-screen "> {/* container */}
  <div class = "flex  pt-2 px-4 py-2 justify-between items-center"> {/* header */}
      <div class = "mr-auto">
          <span class ='grow text-black-100 font-bold  text-xl  hover:text-transparent  hover:bg-clip-text hover:bg-gradient-to-r hover:from-blue-500 hover:to-purple-700' > <a href="/"> Dylan Garcia </a></span>
      </div>
      <div class = 'px-0 text-black-100 text-l  hover:text-transparent  hover:bg-clip-text hover:bg-gradient-to-r hover:from-blue-500 hover:to-purple-700'>

        <Link to='/#portfolio'></Link>
      </div>

      <div class = 'px-2 text-black-100 text-l  hover:text-transparent  hover:bg-clip-text hover:bg-gradient-to-r hover:from-blue-500 hover:to-purple-700'>
        <a href="/CV"> CV </a>
      </div>

      <div class = "text-black-100 text-l  hover:text-transparent  hover:bg-clip-text hover:bg-gradient-to-r hover:from-blue-500 hover:to-purple-700">
        <a href="/About"> About </a>
      </div>
    </div>

<div class = "flex ">
  <div class = "block pt-20 mt-20 leading-6 m-auto w-1/3 tracking-wider text-gray-900">

My name is Dylan Garcia.

<br/><br/>
I am a quantitative user researcher with 3 years of experience in research-based design, data visualization, and product developement.

My enthusiasm for design stems from undergraduate & graduate classes in UI/UX. I further explored this in my masters programme at UCL, of which I was awarded Distinction.

<br/> <br/>In my free time, I enjoy photography, good movies, reading research papers, and learning about social sciences & human behavior.

  </div>


  <div class = "px-5 m-auto mt-20 w-80 h-30">
    <br/>  <br/>
  <img src={me2} alt="UCL" />
  </div>
</div>



<div class = "flex flex-wrap pt-20 justify-center ">

<div class = "py-2 font-bold" >
Education
</div>



</div>

<div class = "flex flex-wrap ml-auto mr-auto justify-center ">

<div class =  "w-40 mr-1 ml-1 mb-1 bg-white-900 shadow-lg hover:shadow-gray-400 overflow-hidden rounded-2xl">
     <img src={ucl} alt="UCL" />
     <div class = "flex justify-center mt-1 font-medium px-1 text-[8.5px]">
     MSc Human-Computer Interaction
     </div>
        </div>
        <div class =  "w-40  ml-1  mb-1 bg-white-900 shadow-lg hover:shadow-gray-400 overflow-hidden rounded-2xl ">
             <img src={brown} alt="UCL" />
             <div class = "flex font-medium mt-1 justify-center px-6 text-[8.5px]">
             BS in Applied Math & CS
             </div>
                </div>
                <div class =  "w-40  ml-1 mb-1 bg-white-900 shadow-lg hover:shadow-gray-400 overflow-hidden rounded-2xl ">
                     <img src={risd} alt="UCL" />
                     <div class = "flex  mt-1 font-medium  justify-center px-2 text-[6.5px]">
                     Couresework in Photography & Design
                     </div>
                        </div>

</div>


<div class = "py-5">


</div>



<div class = "flex justify-center font-bold" >
Technologies Used
</div>

<div class = "flex justify-center flex-wrap ml-auto mr-auto ">

<div class =  "w-40 mr-1 ml-1 mb-1 bg-white-900 shadow-lg hover:shadow-gray-400 overflow-hidden rounded-2xl">
     <img src={figma} alt="UCL" />

        </div>
        <div class =  "w-40  ml-1  mb-1 bg-white-900 shadow-lg hover:shadow-gray-400 overflow-hidden rounded-2xl ">
             <img src={python} alt="UCL" />

                </div>
                <div class =  "w-40  ml-1 mb-1 bg-white-900 shadow-lg hover:shadow-gray-400 overflow-hidden rounded-2xl ">
                     <img src={react} alt="UCL" />

                        </div>




</div>

<div class = "flex justify-center">

<div class =  "w-40  ml-1 mb-1 bg-white-900 shadow-lg hover:shadow-gray-400 overflow-hidden rounded-2xl ">
     <img src={java} alt="UCL" />

        </div>

<div class =  "w-40  ml-1 mb-1 bg-white-900 shadow-lg hover:shadow-gray-400 overflow-hidden rounded-2xl ">
     <img src={docker} alt="UCL" />

        </div>
        <div class =  "w-40  ml-1 mb-1 bg-white-900 shadow-lg hover:shadow-gray-400 overflow-hidden rounded-2xl ">
             <img src={amazon} alt="UCL" />

                </div>

                                                                </div>

                                        <div class = "flex justify-center">
                                        <div class =  "w-40  ml-1 mb-1 bg-white-900 shadow-lg hover:shadow-gray-400 overflow-hidden rounded-2xl ">
                                             <img src={jup} alt="UCL" />

                                                </div>
                                                <div class =  "w-40  ml-1 mb-1 bg-white-900 shadow-lg hover:shadow-gray-400 overflow-hidden rounded-2xl ">
                                                     <img src={kubernetes} alt="UCL" />

                                                        </div>
                                                        <div class =  "w-40  ml-1 mb-1 bg-white-900 shadow-lg hover:shadow-gray-400 overflow-hidden rounded-2xl ">
                                                             <img src={git} alt="UCL" />

                                                                </div>

                                                                                        </div>

                                                                                        <div class = "flex justify-center">
                                                                                        <div class =  "w-40  ml-1 mb-1 bg-white-900 shadow-lg hover:shadow-gray-400 overflow-hidden rounded-2xl ">
                                                                                             <img src={adobe} alt="UCL" />

                                                                                                </div>
                                                                                                <div class =  "w-40  ml-1 mb-1 bg-white-900 shadow-lg hover:shadow-gray-400 overflow-hidden rounded-2xl ">
                                                                                                     <img src={miro} alt="UCL" />

                                                                                                        </div>
                                                                                                        <div class =  "w-40  ml-1 mb-1 bg-white-900 shadow-lg hover:shadow-gray-400 overflow-hidden rounded-2xl ">
                                                                                                             <img src={jira} alt="UCL" />
                                                                                                                </div>
                                                                                                                </div>



</div>


<div class="mt-16 flex flex-col items-center"><div class="mb-3 flex space-x-4"><a class="text-lg text-gray-500 transition hover:" target="_blank" rel="noopener noreferrer" href="mailto:contact@dylangarcia.io"><span class="sr-only">mail</span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="fill-current text-gray-700 hover:text-orange-500 dark:text-gray-200 dark:hover:text-purple-400 h-6 w-6"><path d="M2.003 5.884 10 9.882l7.997-3.998A2 2 0 0 0 16 4H4a2 2 0 0 0-1.997 1.884z"></path><path d="m18 8.118-8 4-8-4V14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8.118z"></path></svg></a>

{/*
<a class="text-sm text-gray-500 transition hover:text-gray-600" target="_blank" rel="noopener noreferrer" href="https://dgarci11github.com"><span class="sr-only">github</span><svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="fill-current text-gray-700 hover:text-purple-500 dark:text-gray-200 dark:hover:text-purple-400 h-6 w-6">
<path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"></path></svg></a>
*/}


<a class="text-sm text-gray-500 transition hover:text-gray-600" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com"><span class="sr-only">linkedin</span><svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="fill-current text-gray-700 hover:text-blue-500 dark:text-gray-200 dark:hover:text-blue-400 h-6 w-6"><path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433a2.062 2.062 0 0 1-2.063-2.065 2.064 2.064 0 1 1 2.063 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"></path></svg></a>



</div><div class="mb-2 flex space-x-2 text-sm text-gray-500 dark:text-gray-400"><div>Dylan Garcia ©2022</div></div><div class="mb-8 text-sm text-gray-500 dark:text-gray-400"><a target="_blank" rel="noopener noreferrer"> contact@dylangarcia.io</a></div></div>


</div>











  );
}

export default About;
